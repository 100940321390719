
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, FormSection, FormSubmission, Patient } from '@/models';
import { BaseButton, ButtonLink, SmartFormComplete, SmartFormSection } from '@/lib/components';
import LensOrdering from '@/custom/menicon/views/encounter/LensOrdering.vue';
import LensOrderingConfirmation from '@/custom/menicon/views/encounter/LensOrderingConfirmation.vue';
import { ILensOrderingAnswer } from '@/custom/menicon';
import { EncounterData } from '@/helpers/encounter.helper';
import { countErrors } from '@/helpers/validators.helper';
import { SavingStatus } from '@/lib/constants';

@Options({
  components: {
    SmartFormSection,
    WorkflowLayout,
    LensOrdering,
    LensOrderingConfirmation,
    BaseButton,
    SmartFormComplete,
    ButtonLink
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    patient: {
      type: Object,
      required: true
    },
    journeyType: {
      type: String,
      required: true
    },
    currentPrescriptions: {
      type: Object,
      default: null
    },
    encounterData: {
      type: Object,
      default: null
    },
    encounter: {
      type: Object,
      default: null
    },
    laterality: {
      type: String,
      default: null
    },
    formSubmission: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    },
    directOrder: {
      type: Boolean,
      default: true
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  encounterId!: string;
  patientId!: string;
  patient!: Patient;
  formSubmission!: FormSubmission | null;
  encounterData!: EncounterData | null;
  encounter!: Encounter;
  directOrder!: boolean;
  errors!: { [key: string]: string[] };
  rules!: { [key: string]: any };
  prescriptionData: ILensOrderingAnswer | null = null;
  initialEncounter!: EncounterData;
  reasonForOrderSectionId = 'ef610b84-c880-4860-8bdc-c4ae1eedbfe9';

  get steps(): string[] {
    return [
      this.$t('custom.menicon.encounter.reason-for-order.name') as string,
      this.$t('custom.menicon.encounter.lens-ordering.name') as string,
      this.$t('custom.menicon.encounter.lens-ordering-confirmation.name') as string
    ];
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get reasonForOrderSection(): FormSection | undefined {
    return this.formSubmission?.form?.schema?.sections.find(
      (section: FormSection) => section.id === this.reasonForOrderSectionId
    );
  }

  get topographyScans() {
    return this.initialEncounter?.topographyScans;
  }

  get currentSectionId(): string | null {
    if (this.step === 0) {
      return this.reasonForOrderSectionId;
    }
    return null;
  }

  get smartFormCompleteProps() {
    if (this.directOrder) {
      return {
        heading: this.$t('custom.menicon.prescriptions.order-placed-heading') as string,
        body: this.$t('custom.menicon.prescriptions.order-placed-body') as string
      };
    }

    return {
      heading: this.$t('custom.menicon.prescriptions.consult-complete-heading') as string,
      body: this.$t('custom.menicon.prescriptions.consult-complete-body') as string
    };
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  validateCurrentStep() {
    let errors = {};
    if (this.step === 1 && this.prescriptionData) {
      errors = this.prescriptionData.errors;
    }
    this.$emit('setErrors', errors);
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next', this.currentSectionId);
    }
  }

  complete() {
    this.$emit('complete', { sectionId: this.currentSectionId, order: this.prescriptionData });
  }

  back() {
    this.$emit('back');
  }
}
